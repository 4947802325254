<template>
  <div>
    <vs-popup class="sm:popup-w-70" title="Set Properti Tipe Per Proyek" :active="isActive"
              v-on:update:active="emitModalIsActive">
      <div>

        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>

        <div class="vx-row">
          <!--loading-->
          <vs-progress indeterminate color="primary" class="mx-5" :height="1" v-if="isLoadingGetData"/>

          <div class="overflow-x-auto mx-5 py-3 w-full" v-if="!isLoadingGetData">
            <table class="border-collapse table-auto w-full" v-if="isActive">
              <!--table header-->
              <tr>
                <th class="p-2 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap"
                    rowspan="2">Properti Tipe
                </th>
                <th class="p-2 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap"
                    :colspan="xData.length">Proyek
                </th>
              </tr>
              <tr>
                <th class="p-2 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap" v-for="(item, index) in xData" :key="index">
                  {{ item.kode }}
                  <vs-checkbox class="justify-center" v-model="item.checkAll" @change="toggleCheckAll($event.target.checked, item.id)"></vs-checkbox>
                </th>
              </tr>

              <!--table data-->
              <tr v-for="(itemY, index) in yData" :key="index">
                <td class="p-2 border border-solid d-theme-border-grey-light">{{ itemY.nama }}</td>
                <td class="border border-solid d-theme-border-grey-light" v-for="(itemX, index) in xData"
                    :key="index">
                  <vs-checkbox class="justify-center" :checked="isItemChecked(itemY.details, itemX.id)"
                               @change="onChecked($event.target.checked, itemY.id, itemX.id)"/>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading || data.changes.length === 0" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>

      </div>
    </vs-popup>
  </div>
</template>

<script>
import PropertiTipeRepository from '@/repositories/master/properti-tipe-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import _ from 'lodash'

export default {
  name: 'PropertiTipeSetPerProyek',
  components: {
    ValidationErrors
  },
  props: ['isActive'],
  data () {
    return {
      isLoading: false,
      isLoadingGetData: false,
      errors: null,
      data: {
        changes: []
      },
      yData: [],
      xData: []
    }
  },
  watch: {
    isActive (active) {
      this.data.changes = []
      if (active) {
        this.getDataY()
      }
    }
  },
  methods: {
    save () {
      this.errors = null
      this.isLoading = true

      PropertiTipeRepository.setPerProyek(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    getDataY () {
      this.isLoadingGetData = true

      PropertiTipeRepository.getAllWithDetails()
        .then(response => {
          this.yData = response.data.data
          this.getDataX()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
    },

    getDataX () {
      ProyekRepository.getAll()
        .then(response => {
          this.xData = response.data.data
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoadingGetData = false
        })
    },

    onChecked (isChecked, idY, idX) {
      const newObject = {
        checked: isChecked,
        id_properti_tipe: idY,
        id_proyek: idX
      }

      // find if exists
      const index = _.findIndex(this.data.changes, (o) => {
        return idY === o.id_properti_tipe && idX === o.id_proyek
      })

      // replace or push
      if (index !== -1) {
        this.data.changes[index] = newObject
      } else {
        this.data.changes.push(newObject)
      }
    },

    isItemChecked (details, idX) {
      return _.some(details, (o) => {
        return idX === o.id_proyek
      })
    },

    toggleCheckAll (isChecked, idX) {
      // TODO : Belu Selesai
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
